@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: " *";
}

.event-link-expired .ant-table-cell-fix-left{
  background-color: rgb(243 244 246);
  color: rgb(183, 181, 181)
}

.ant-btn-primary {
  border-color: #832f91;
  background-color: #832f91;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #bf3888;
  background: #bf3888;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  border-color: #8b2f8d;
  color: #8b2f8d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
